/* eslint-env browser */
const React = require('react');
const PropTypes = require('prop-types');
const ComponentPrints = require('@services/Tracking/resources/ComponentPrints');
const displayName = require('./utils');
const { LandingContext } = require('@splinter/context');

const whenIdle = global.requestIdleCallback ? requestIdleCallback : setTimeout;

function withTracker(WrappedComponent) {
  class WithTracker extends React.Component {
    constructor(props) {
      super(props);
      this.track = this.track.bind(this);
      this.element = React.createRef();
    }

    componentDidMount() {
      if (this.element.current) {
        this.observer = new IntersectionObserver(this.track, { threshold: 0.5 });
        this.observer.observe(this.element.current);
      }
    }

    track(entries) {
      const { component, analytics, webView, newPropsTrackingFlag } = this.props;
      const { uuid } = this.context;

      if (!entries[0].isIntersecting) return;
      this.observer.unobserve(this.element.current);
      this.observer.disconnect();
      whenIdle(() => {
        ComponentPrints.sendPrint(component, analytics, webView, uuid, newPropsTrackingFlag);
      });
    }

    render() {
      const {
        component: { component_name },
      } = this.props;
      return (
        <div className={component_name} ref={this.element}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }
  WithTracker.contextType = LandingContext;
  WithTracker.displayName = displayName(WithTracker, WrappedComponent);
  WithTracker.defaultProps = {
    track: null,
    className: '',
  };
  WithTracker.propTypes = {
    className: PropTypes.string,
    track: PropTypes.func,
    component: PropTypes.shape({
      component_name: PropTypes.string.isRequired,
    }).isRequired,
    analytics: PropTypes.string.isRequired,
    webView: PropTypes.bool.isRequired,
  };

  return WithTracker;
}

module.exports = withTracker;
