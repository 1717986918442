require('core-js/stable');
require('regenerator-runtime/runtime');

class ComponentPrints {
  /**
   * Send prints
   * @param {object} component
   * @param {number} componentIndex
   * @returns {void}
   */
  static sendPrint(component, analytics, webView, viewDealPrintId, newPropsTrackingFlag) {
    if (typeof melidata === 'function') {
      if (component.data?.tracking && component.data?.tracking.flow === 'dlh' && newPropsTrackingFlag) {
        const { eventData } = component.data.tracking;
        const meliTrack = {};
        melidata('createEventTrack', meliTrack);
        melidata('withPath', '/splinter/component', meliTrack);
        melidata('withData', { ...eventData, c_tracking_id: viewDealPrintId }, meliTrack);
        melidata('withStream', 'component_prints', meliTrack);
        melidata('sendTrack', meliTrack, 'component_prints');
      } else {
        const { component_name, data, items, variations } = component;
        const componentItems = ['CarouselDynamic', 'Grid', 'Carousel', 'MainSlider', 'CategoryList'];
        const excludeComponents = ['Tracking', 'Disclaimer'];
        let eventData = {};

        if (items.length > 0) {
          if (items.some(item => item.data?.tracking?.flow === 'ui' && newPropsTrackingFlag)) return;
          items.map((item, index) => {
            const isCIDItemOrCategoryItem = !!item?.componentName;
            if (isCIDItemOrCategoryItem) {
              const link = data?.viewMoreLink || item.data?.permalink;
              const label = data.title;
              const container_id = link?.container_id;
              eventData.c_id = `/splinter/${item.componentName.toLowerCase()}`;
              eventData.c_element_order = index + 1 || 'N/A';
              eventData.c_campaign = label;
              eventData.c_uid = viewDealPrintId;
              eventData.c_container_id = container_id ?? 'not_apply';

              const meliTrack = {};
              melidata('createEventTrack', meliTrack);
              melidata('withPath', '/splinter/component', meliTrack);
              melidata('withData', eventData, meliTrack);
              melidata('withStream', 'component_prints', meliTrack);
              melidata('sendTrack', meliTrack, 'component_prints');
            } else {
              const isCarousel = component_name === 'Carousel';
              const isMerchEngineMS = component_name === 'MainSliderPlacement';
              const link = item.data?.link;
              const label = item.data?.label;
              const container_id = link?.container_id;

              const c_id = `/splinter/${item.component_name}${
                item.variations !== 'normal' ? `-${!isCarousel ? item.variations : variations}` : ''
              }`;

              eventData.c_container_id = container_id ?? 'not_apply';
              eventData.c_id = c_id.toLowerCase();
              eventData.c_element_order = !isCarousel ? item.data.element_order : index + 1 || 'N/A';
              eventData.c_campaign = label;
              eventData.c_uid = viewDealPrintId;
              if (isMerchEngineMS) {
                eventData = {
                  ...eventData,
                  ...item.data.event_data,
                };
              }

              const meliTrack = {};
              melidata('createEventTrack', meliTrack);
              melidata('withPath', '/splinter/component', meliTrack);
              melidata('withData', eventData, meliTrack);
              melidata('withStream', 'component_prints', meliTrack);
              melidata('sendTrack', meliTrack, 'component_prints');
            }
          });
        }

        if (!componentItems.includes(component_name) && !excludeComponents.includes(component_name)) {
          const link = data?.link;
          const container_id = link?.container_id;
          const label = data?.label;

          eventData.c_id = `/splinter/${component_name.toLowerCase()}${
            variations !== 'normal' ? `-${variations.toLowerCase()}` : ''
          }`;

          eventData.c_container_id = container_id ?? 'not_apply';
          eventData.c_element_order = data.element_order || 'N/A';
          eventData.c_campaign = label;
          eventData.c_uid = viewDealPrintId;
          const meliTrack = {};
          melidata('createEventTrack', meliTrack);
          melidata('withPath', '/splinter/component', meliTrack);
          melidata('withData', eventData, meliTrack);
          melidata('withStream', 'component_prints', meliTrack);
          melidata('sendTrack', meliTrack, 'component_prints');
        }
      }
    }
  }
}

module.exports = ComponentPrints;
