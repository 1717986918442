const restclient = require('nordic/restclient')({
  baseURL: '/ofertas/api',
});

module.exports = {
  /**
   * Retrieve Array of ids from CID
   * @param {boolean} isClientSide
   * @param {object[]} carousels
   * @param {string} siteId
   * @returns {string[]}
   */
  retrieveCIDItemsIds: async (isClientSide, carousels, siteId) => {
    if (!isClientSide) {
      return carousels.flatMap(cid => cid.items_id);
    }
    const [ssrCIDs, clientSideCIDs] = [[], []];
    carousels.forEach(carousel => (carousel.clientSide ? clientSideCIDs.push(carousel) : ssrCIDs.push(carousel)));
    const ssrCIDItems = ssrCIDs.flatMap(el => el.items_id);
    const clientSidePromises = clientSideCIDs.map(async cid => {
      try {
        const { data: itemsIds } = await restclient.post('/dynamic-carousel/items-ids', {
          data: { filters: cid.filters, siteId },
        });
        return itemsIds;
      } catch {
        return [];
      }
    });
    const clientSideItemsHydrated = await Promise.all(clientSidePromises);
    return [...ssrCIDItems, ...clientSideItemsHydrated.flat()];
  },
  /**
   * Retrieve the final path for the landing check if (pre, post)...
   * @param {string} landingName
   * @param {string} type
   * @param {string} url
   * @param {string} folder
   * @returns {string}
   */
  formatLandingNameByType: (landingName, type, url, folder = '') => {
    if (url) {
      const urlFragments = url.split('/');
      if (urlFragments.length === 4) {
        const subcategory = urlFragments[urlFragments.length - 1];
        return `_${subcategory}`;
      }
    }

    const reg = new RegExp(/(a|h|c|l)(\/(.*))/gm);

    if (folder.search(reg) !== -1) {
      if (type === 'home') {
        return '';
      }

      return `_${type.toUpperCase()}`;
    }

    if (type === 'pre') return `/${landingName}_PRE`;
    if (type === 'post') return `/${landingName}_POST`;
    return `/${landingName}`;
  },

  /**
   * Retrieve the path for the current view
   * @param {object} dataLanding
   * @param {string} landingType
   * @returns {object}
   */
  getTrackingPath: pathDeals => {
    return {
      melidata: {
        form: '/splinter/form/submit',
        deals: '/splinter/landing',
        categories: {
          view: '/category_landing',
          event: '/category_landing/tap',
        },
        mercadoshops: '/mercado_shops/landing',
        melicoins: '/splinter/landing',
      },
      meliGA: {
        section: {
          deals: 'SPLINTER',
          categories: '/',
          melicoins: 'SPLINTER/',
        },
        page: {
          deals: pathDeals || '',
          categories: 'CATEGORY_LANDING',
          melicoins: 'LANDING/CRYPTO',
        },
        business: 'MARKETPLACE',
        pageVertical: 'CORE',
      },
    };
  },
  motorsIds: ['MLA1743', 'MLB1743', 'MLM1743', 'MCO1743', 'MLC1743', 'MLU1743', 'MLV1743'],
  realEstateIds: ['MLA1459', 'MLB1459', 'MLM1459', 'MCO1459', 'MLC1459', 'MLU1459', 'MLV1459'],
};
